import React from 'react';
import {
  FORMATTER,
  THRESHOLDING,
} from './Constant'

class Records extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let trList = [];
    for (let i in this.props.records) {
      let tdList = [];
      for (let key of this.props.columns) {
        let formatter = FORMATTER[key] ? FORMATTER[key] : FORMATTER['default'];
        let thresholding = THRESHOLDING[key] ? THRESHOLDING[key] : THRESHOLDING['default'];
        tdList.push(<td class={thresholding(formatter(this.props.records[i][key])) ? "danger" : "" }>
          {this.props.records[i][key]}
        </td>);
      }
      trList.push(<tr>{tdList}</tr>);
    }
    return (
      <tbody>
        {trList}
      </tbody>
    );
  }
}

export default Records;
