import React from 'react';

import {  Navbar,
          FormGroup,
          FormControl
        } from 'react-bootstrap';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.filter = props.filter
  }

  render() {
    return (
      <Navbar inverse>
        <Navbar.Header>
          <Navbar.Brand>
            GPU Monitoring
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Navbar.Form pullLeft>
            <FormGroup>
              <FormControl type="text" placeholder="filter by hostname (OR Search)" onChange={this.filter} style={{width: "250px"}} />
            </FormGroup>{' '}
          </Navbar.Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default FilterBar;
