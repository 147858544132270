export const GPU_DATA_JSON_API_PATH = 'https://gpu.imlab.jp/gpu_data_json.php?type=';
export const TYPE_PARAMS = {'': 'GPU Usage', 'process': 'Processes', 'imageinfo': 'Image Info'};
export const KEY_SUFFIX = 'gpu';

// Keys for associative array
export const COLUMNS = 'columns';
export const ROWS = 'rows';
export const FILTER_STRING = 'filterString';
export const HOSTNAME = 'hostname';

export const FORMATTER = {
  'timestamp': (value) => {
    return (new Date(value)).getTime();
  },
  'default': (value) => {
    try {
      let m = value.match(/^(\d+\.?\d*|\.\d+).*/);
      if (!m) {
        return value;
      }
      return Number(value.match(/\d+\.?\d*|\.\d+/)[0]);
    } catch (e) {
      return value;
    }
  }
};

export const THRESHOLDING = {
  'timestamp': (value) => {
    return (new Date()).getTime() - value > 60 * 5 * 1000;
  },
  'utilization.gpu [%]': (value) => {
    return value > 70;
  },
  'temperature.gpu': (value) => {
    return value > 80;
  },
  'default': (value) => {
    return false;
  }
};
