import React from 'react';
import Columns from './Columns';
import Records from './Rows';
import { Table } from 'react-bootstrap';

class Content extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Table striped bordered condensed hover>
        <Columns tableName={this.props.tableName} columns={this.props.columns} sort={this.props.sort} />
        <Records records={this.props.records} columns={this.props.columns}/>
      </Table>
    );
  }
}

export default Content;
