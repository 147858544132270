import React from 'react';

import {  Button,
          Glyphicon
        } from 'react-bootstrap';

class Columns extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDescendinButton(columnName) {
    return (
      <Button bsStyle="link" onClick={() => this.props.sort(this.props.tableName, columnName, false)} >
        <Glyphicon glyph="chevron-down" />
      </Button>
    );
  }

  renderAscendingButton(columnName) {
    return (
      <Button bsStyle="link" onClick={() => this.props.sort(this.props.tableName, columnName, true)} >
        <Glyphicon glyph="chevron-up" />
      </Button>
    );
  }

  render() {
    let list = [];
    for (let i in this.props.columns) {
      list.push(
        <th>
          <p class="text-center">
            {this.props.columns[i]}
          </p>
            {this.renderAscendingButton(this.props.columns[i])}
            {this.renderDescendinButton(this.props.columns[i])}
        </th>);
    }
    return (
      <thead>
        <tr>
          {list}
        </tr>
      </thead>
    );
  }
}

export default Columns;
