import React from 'react';
import FilterBar from './FilterBar'
import Content from './Content'
import {
  GPU_DATA_JSON_API_PATH,
  TYPE_PARAMS,
  KEY_SUFFIX,
  COLUMNS,
  ROWS,
  FILTER_STRING,
  HOSTNAME,
  FORMATTER
} from './Constant'

class App extends React.Component {
  constructor() {
    super();

    this.data = {};
    for (let v in TYPE_PARAMS) {
      this.data[v + KEY_SUFFIX] = {
        COLUMNS: [],
        ROWS: []
      };
    }
    this.state = JSON.parse(JSON.stringify(this.data));
    this.state[FILTER_STRING] = '';

    this.filter = this.filter.bind(this);
    this.sort = this.sort.bind(this);
    this.setState = this.setState.bind(this);
  }

  filter(e) {
    console.log('filtering', e.target.value);
    let filterStr = e.target.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    let filterStrArr = filterStr.split(/\s+/);

    this.state[FILTER_STRING] = filterStr;
    for (let v in TYPE_PARAMS) {
      this.state[v + KEY_SUFFIX][ROWS] = this.data[v + KEY_SUFFIX][ROWS].filter((item) => {
        for (let i in filterStrArr) {
          if (item[HOSTNAME].search(filterStrArr[i]) != -1) return true;
        }
        return false;
      });
    }
    this.setState(this.state);
  }

  sort(tableName, columnName, ascending) {
    console.log('sort', tableName, columnName, ascending);
    this.data[tableName][ROWS].sort((a, b) => {
      let formatter = FORMATTER[columnName] ? FORMATTER[columnName] : FORMATTER['default'];
      a = formatter(a[columnName]);
      b = formatter(b[columnName]);
      if (a < b) return ascending ? -1 : 1;
      if (a > b) return ascending ? 1 : -1;
      return 0;
    });
    this.filter({target: {value: this.state[FILTER_STRING]}});
  }

  componentDidMount() {
    for (let v in TYPE_PARAMS) {
      fetch(GPU_DATA_JSON_API_PATH + v)
        .then(response => response.json())
        .then(data => {
          this.data[v + KEY_SUFFIX] = data;
          this.state[v + KEY_SUFFIX] = JSON.parse(JSON.stringify(data));
          this.setState(this.state);
        });
    }
  }

  render() {
    let content = [<FilterBar filter={this.filter} />];
    for (let key in TYPE_PARAMS) {
      content.push(<h2>{TYPE_PARAMS[key]}</h2>);
      content.push(<Content tableName={key + KEY_SUFFIX}
        columns={this.state[key + KEY_SUFFIX][COLUMNS]}
        records={this.state[key + KEY_SUFFIX][ROWS]} sort={this.sort} />);
    }
    return content;
  }
}

export default App;
